import React, { useState } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { useLocation, useNavigate } from 'react-router-dom';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

interface InputFieldProps {
  label: string;
  placeholder: string;
  type?: string;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, type = "text" }) => (
  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow pr-5 ml-10 text-lg font-medium tracking-normal max-md:mt-10 max-md:max-w-full">
      <div className="text-neutral-500 max-md:max-w-full">{label}</div>
      <input
        type={type}
        placeholder={placeholder}
        className="px-4 py-6 mt-6 bg-gray-50 rounded-lg border border-solid border-zinc-300 text-neutral-400 w-[400px] max-md:pr-5 max-md:max-w-full"
      />
    </div>
  </div>
);

interface SelectFieldProps {
  label: string;
  options: string[];
}

const SelectField: React.FC<SelectFieldProps> = ({ label, options }) => (
  <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
    <div className="flex flex-col mt-auto mr-10 h-auto text-lg font-medium tracking-normal grow-0 max-md:mt-10 max-md:max-w-full">
      <div className="text-neutral-500 max-md:max-w-full">{label}</div>
      <select className="flex gap-5 px-5 py-6 mt-5 bg-gray-50 rounded-lg border border-solid border-zinc-300 text-neutral-400 w-[400px] max-md:flex-wrap max-md:max-w-full">
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const DocumentForm: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);

  const location = useLocation();
  const submissionKey = location.state?.submissionKey || 'submission1';
  const stirlingPdfBaseUrl = process.env.REACT_APP_STIRLING_PDF_BASE_URL;
  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;

  const navigate = useNavigate();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const savePDFToLocalStorage = async (pdfFile: File, key: string) => {
    const formData = new FormData();
    formData.append('fileInput', pdfFile);
    formData.append('pageNumbers', "all");
    
    try {
      const response = await axios.post('http://localhost:8080/api/v1/general/split-pages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer'  // Specify the response type as arraybuffer
      });

      const zipArrayBuffer = response.data;
      console.log('Received zip file:', zipArrayBuffer);

      const zip = new JSZip();
      const zipData = await zip.loadAsync(zipArrayBuffer);

      const pdfPages: { [pageNo: string]: string } = {};
      for (const [filename, file] of Object.entries(zipData.files)) {
        if (!file.dir && filename.endsWith('.pdf')) {
          const pdfArrayBuffer = await file.async('arraybuffer');
          const base64PDF = btoa(
            new Uint8Array(pdfArrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          const pageNo = filename.replace('.pdf', '');
          pdfPages[pageNo] = `data:application/pdf;base64,${base64PDF}`;
        }
      }

      console.log('Extracted PDF pages:', pdfPages);

      // Now proceed to save the new PDF pages
      Object.entries(pdfPages).forEach(([pageNo, base64PDF]) => {
        const pageNumber = pageNo.split('_').pop() || pageNo;
        localStorage.setItem(`${key}-page${pageNumber}`, base64PDF);
        console.log(`PDF page ${pageNumber} saved as ${key}-page${pageNumber} in localStorage`);
      });
    } catch (error) {
      console.error('Error splitting PDF:', error);
      // You might want to add more detailed error logging here
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  };

  React.useEffect(() => {
    if (submissionKey) {
      console.log(`Document is coming from ${submissionKey}`);
      // Additional logic if needed
    }
  }, [submissionKey]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile);
      // if (submissionKey) {
      //   savePDFToLocalStorage(uploadedFile, submissionKey);
      // }
    }
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [images, setImages] = useState<string[]>([]);

  const clearExcalidrawData = () => {
    // Loop through the localStorage keys
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key starts with 'excalidrawData_'
      if (key && submissionKey && key.startsWith(submissionKey+'excalidrawData_')) {
        localStorage.removeItem(key);
      }
    }

    console.log('Excalidraw data has been cleared from localStorage.');
  };

  // Initialize the S3 client
  const s3Client = new S3Client({
    region: awsRegion,
    credentials: {
      accessKeyId: awsAccessKeyId!,
      secretAccessKey: awsSecretAccessKey!,
    },
  });

  const handleZipFile = async (zipBlob: Blob) => {
    try {
      const zip = new JSZip();
      const zipData = await zip.loadAsync(zipBlob);
  
      const extractedImages: string[] = [];
      let pageIndex = 1; // Initialize page index
      for (const filename of Object.keys(zipData.files)) {
        const file = zipData.files[filename];
  
        if (!file.dir && /\.(png|jpe?g|gif)$/i.test(filename)) {
          const imageBlob = await file.async('blob');
          
          // Upload image to S3
          const s3Key = `gollab/documents/${submissionKey}-page${pageIndex}.png`;
          const uploadParams = {
            Bucket: awsS3Bucket!, // Ensure this is correctly set
            Key: s3Key,
            Body: imageBlob,
            ContentType: 'image/png', // Adjust the content type as needed
          };

          console.log('Uploading to S3 with params:', uploadParams);

          const response = await s3Client.send(new PutObjectCommand(uploadParams));
          console.log('S3 upload response:', response);

          const s3Url = `https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${s3Key}`;
          console.log(s3Url);
          
          const base64Image = await blobToBase64(imageBlob, 'image/png');
          extractedImages.push(base64Image);

          localStorage.setItem(`${submissionKey}-page${pageIndex}`, s3Url);

          pageIndex++; // Increment page index for the next image
        }
      }
  
      console.log(extractedImages);
      // here we are clearing the localStorage in order to prevent the previous elements in new pdf images.
      clearExcalidrawData();
      // if (submissionKey) {
      //   localStorage.setItem(submissionKey+'pdfImages', JSON.stringify(extractedImages));
      // }
      
    } catch (error) {
      console.error('Error extracting images from ZIP file:', error);
    }
  };
  
  // Helper function to convert Blob to base64 with specified MIME type
  const blobToBase64 = (blob: Blob, mimeType: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        // Ensure the base64 string has the correct MIME type
        const base64Image = `data:${mimeType};base64,${base64Data.split(',')[1]}`;
        resolve(base64Image);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!file) return;
  
    setIsProcessing(true);
  
    try {
      // Upload the PDF to StirlingPDF
      const formData = new FormData();
      formData.append('fileInput', file);
      formData.append('imageFormat', 'jpeg');
      formData.append('singleOrMultiple', 'multiple');
      formData.append('colorType', 'color');
      formData.append('dpi', '600');
  
      try {
        const imageResponse = await axios.post(stirlingPdfBaseUrl+'/api/v1/convert/pdf/img', formData, {
          withCredentials: false,
          responseType: 'blob',
          headers: {
            // 'Content-Type': 'application/json',           
          }
        });
  
        if (imageResponse.statusText !== 'OK') {
          console.error('HTTP error', imageResponse.status);
          return;
        }        
      
        const imageBlob = await imageResponse.data;       
        if (imageBlob.size === 0) {
          console.error('Received empty image blob');
          return;
        }
      
        // Assuming handleZipFile processes the image blob or URL
        await handleZipFile(imageBlob);
  
        // Redirect to ExcalidrawCanvas after saving images, passing the submissionKey
        navigate('/playground', { state: { imageIndex: 0, submissionKey } });
  
      } catch (error) {
        console.error('Error converting PDF to image:', error);
      }      
  
    } catch (error) {
      console.error('Error processing PDF:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
    <form className="flex flex-col items-start" onSubmit={handleSubmit}>
      <div className="self-stretch mt-10 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document name" placeholder="Enter document name" />
          <SelectField label="Document type" options={["Select document type"]} />
        </div>
      </div>
      <div className="self-stretch mt-10 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document category" placeholder="Enter document category" />
          <SelectField label="Document needed" options={["Mandatory"]} />
        </div>
      </div>
      <div
        className={`box-border flex relative flex-col shrink-0 ml-10 ${dragActive ? 'border-dashed border-emerald-500' : 'border-emerald-800'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="mt-11 text-lg font-medium tracking-normal text-neutral-500 max-md:mt-10 max-md:max-w-full">
          Upload
        </div>
        <div className="flex justify-center items-center py-10 pr-10 pl-16 mt-5 mr-auto max-w-full rounded-2xl border border-dashed bg-emerald-600 bg-opacity-10 w-[579px] max-md:px-5">
          <div className="flex gap-4 items-center max-md:flex-wrap">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c21eaadb9f35f9972215fa476f1f526a81fbb8c48d30d182bb4cf20b0521ec0?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
              alt=""
              className="shrink-0 self-stretch aspect-[0.81] w-[34px]"
            />
            <div className="self-stretch my-auto text-xl font-medium text-center text-emerald-800">
              {file ? file.name : 'Drag and Drop documents here or'}
            </div>
            <label className="self-stretch p-4 my-auto text-base font-semibold text-white whitespace-nowrap bg-emerald-600 rounded-md max-md:px-5 cursor-pointer">
              Upload
              <input type="file" className="hidden" onChange={handleFileUpload} />
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-5 justify-between mt-10 ml-10 text-lg font-medium tracking-normal">
        <button type="submit"  disabled={isProcessing} className="self-center p-4 h-auto text-white bg-emerald-600 rounded-lg max-md:px-5">
        {isProcessing ? 'Processing...' : 'Add Document'}
        </button>
        <button type="reset" className="self-center p-4 h-auto whitespace-nowrap bg-white rounded-lg border border-solid border-zinc-300 text-neutral-700 max-md:px-5" onClick={() => setFile(null)}>
          Reset
        </button>
      </div>
    </form>

    <div className="mt-20"></div>

    {/* <div className="mt-10">
    <h2 className="text-lg font-medium">Generated Images:</h2>
    <div className="flex flex-wrap gap-4 mt-4">
      {images.map((src, index) => (
        <img key={index} src={src} alt={`Page ${index + 1}`} className="w-48 h-auto rounded-lg border border-gray-300" />
      ))}
    </div>
    </div> */}
    </>
  );
};

export default DocumentForm;
