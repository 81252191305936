import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../context/SidebarContext';

interface SidebarItemProps {
  icon: string;
  label: string;
  isActive?: boolean;
  hasDropdown?: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, label, isActive = false, hasDropdown = false }) => {
  const baseClasses = "flex gap-1.5 items-center text-base font-semibold text-neutral-500";
  const activeClasses = isActive ? "px-4 py-2.5 w-full rounded bg-neutral-200 bg-opacity-0" : "px-4 py-2.5";

  return (
    <div className={`${baseClasses} ${activeClasses}`}>
      <img loading="lazy" src={icon} alt="" className="shrink-0 self-stretch aspect-[1.1] w-[22px]" />
      <div className="self-stretch my-auto">{label}</div>
      {hasDropdown && (
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/94c42e651f81babda8d43759a9cfe84e451c080676046e7bcd8a8c59da68295c?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 self-stretch my-auto w-3 aspect-square" />
      )}
    </div>
  );
};

const Sidebar: React.FC = () => {

  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate('/documents');
  };

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    const handleToggle = () => {
      toggleSidebar();
    };

    // Listen for the custom 'toggleSidebar' event
    window.addEventListener('toggleSidebar', handleToggle);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('toggleSidebar', handleToggle);
    };
  }, [toggleSidebar]);

  if (!isSidebarOpen) return null; // Do not render the sidebar if it's closed

  return (
    <aside className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow pb-20 mr-auto w-full h-auto bg-white border-r border-gray-200">
        <div className="flex gap-3 px-4 py-3.5 text-sm font-medium leading-5 border-r border-b bg-stone-50 border-zinc-300 text-neutral-400">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8859ba8836f35dd029a986adf46b502f62428ccd291892400b35bb3e022f8e0a?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 aspect-square fill-neutral-400 w-[18px]" />
          <div className="flex-auto my-auto">Search for documents</div>
        </div>
        <nav className="flex flex-col self-center px-5 py-0.5 mt-7 w-full">
          <div className="text-sm font-semibold leading-5 text-neutral-400">
            <span className="text-base">Drawing</span>
            <span className="font-medium text-neutral-400"> (2 documents)</span>
          </div>
          <div className="flex flex-col mt-4">
            <SidebarItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/e49561ca0cf06769d155dc76a60d89f178e73e14bace6c1f25fcc3c22bf956bc?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" label="Traffic Plan" isActive={true} hasDropdown={true} />
            <SidebarItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/e49561ca0cf06769d155dc76a60d89f178e73e14bace6c1f25fcc3c22bf956bc?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" label="Swept Path" />
          </div>
          <div className="mt-5 text-sm font-semibold leading-5 text-neutral-400">
            <span className="text-base">Documents</span>
            <span className="font-medium text-neutral-400"> (2 documents)</span>
          </div>
          <div className="flex flex-col mt-5 text-base font-semibold text-neutral-500">
            <SidebarItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/4f3f20508a914323a298385eeba03867f93473f5437ba7b1f3dd55e8d2b7f4b8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" label="Checklist" />
            <SidebarItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/4f3f20508a914323a298385eeba03867f93473f5437ba7b1f3dd55e8d2b7f4b8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" label="Response sheet" />
          </div>
          <SidebarItem icon="https://cdn.builder.io/api/v1/image/assets/TEMP/97c32fc23344518d58d2f20d564ceea421c1b672611d1e20adad90b4a80b4fda?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" label="Search design manual" />
          <div className="flex flex-col mt-2 text-base font-semibold text-neutral-500">
            <button className="flex gap-2 px-3.5 py-2 rounded bg-neutral-200" onClick={handleUploadClick}>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3dbe94661ced297c689bcca4b20317a41625d5a0b7933566bd19ccf0bfa6d3ea?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-6 aspect-square" />
              <span className="flex-auto my-auto">Upload drawing or document</span>
            </button>
            <button className="px-5 py-3.5">Request drawing or document</button>
            <button className="px-5 py-3.5">Raise drawing and doc request</button>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;