import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ExcalidrawImperativeAPI,
  BinaryFileData,
  DataURL,
  ExcalidrawProps,
} from "@excalidraw/excalidraw/types";
import { ExcalidrawElement, ExcalidrawImageElement, ExcalidrawAnnotationElement, FileId } from "@excalidraw/excalidraw/element/types";
import { Excalidraw } from "@excalidraw/excalidraw";
import { Footer } from "@excalidraw/excalidraw";
import "@excalidraw/excalidraw/index.css";
import type { OverlayModeFormData } from "@excalidraw/excalidraw/components/OverlayMode/OverlayMode";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ImportedDataState } from "@excalidraw/excalidraw/data/types";

// Extend the ExcalidrawProps interface
interface CustomExcalidrawProps extends ExcalidrawProps {
  overlaymodeSelect?: {
    selectOptions1: { label: string; value: string }[];
    selectOptions2: { label: string; value: string }[];
  };
  onOverlayModeSubmit?: (formData: OverlayModeFormData) => void;
}

export default function Playground() {
  const ExcalidrawComponent = Excalidraw as unknown as React.FC<
    CustomExcalidrawProps
  >;

  const location = useLocation();
  const submissionKey = location.state?.submissionKey || "submission1";

  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const [initialData, setInitialData] =
    useState<ImportedDataState | null>(null);
  const imageElementsRef = useRef<{
    [key: number]: readonly ExcalidrawElement[];
  }>({});

  const imgComparisionUrl = process.env.REACT_APP_IMG_COMPARISON_BASE_URL;

  // Create refs for submissionKey and currentImageIndex
  const submissionKeyRef = useRef(submissionKey);
  const currentImageIndexRef = useRef(currentImageIndex);

  // Update refs when values change
  useEffect(() => {
    submissionKeyRef.current = submissionKey;
  }, [submissionKey]);

  useEffect(() => {
    currentImageIndexRef.current = currentImageIndex;
  }, [currentImageIndex]);

  const otherSubmissionKey =
    submissionKey === "submission2" ? "submission1" : "submission2";

  const selectOptions1 = [
    {
      label: `${submissionKey}-page${currentImageIndex + 1}`,
      value: `${submissionKey}-page${currentImageIndex + 1}`,
    },
  ];

  const selectOptions2 = Object.keys(localStorage)
    .filter((key) => key.startsWith(`${otherSubmissionKey}-page`))
    .map((key) => {
      return { label: key, value: key };
    });

  const overlaymodeSelect = {
    selectOptions1,
    selectOptions2,
  };

  localStorage.setItem("submission", submissionKey);

  // Load images from S3 when submissionKey changes
  useEffect(() => {
    // Reset state when submissionKey changes
    setCurrentImageIndex(0);
    setInitialData(null);
    setImages([]);

    const loadImagesFromS3 = async () => {
      if (submissionKey) {
        const imageUrls: string[] = [];
        let pageNo = 1;
        while (true) {
          const s3Url = localStorage.getItem(`${submissionKey}-page${pageNo}`);
          if (!s3Url) break;
          imageUrls.push(s3Url);
          pageNo++;
        }

        if (imageUrls.length > 0) {
          setImages(imageUrls);
          try {
            const response = await fetch(imageUrls[0]);
            const blob = await response.blob();
            const dataUrl = await new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            });
            loadImage(dataUrl, 0, submissionKey);
          } catch (error) {
            console.error("Error loading image from S3:", error);
          }
        } else {
          console.error(
            `No images found in localStorage for submission key: ${submissionKey}`
          );
        }
      }
    };

    loadImagesFromS3();
  }, [submissionKey]);

  // Use useEffect to update the scene when initialData changes
  useEffect(() => {
    if (excalidrawAPI && initialData) {
      console.log("Updating scene with new initial data");
      // Convert files object to array
      const filesArray = Object.values(initialData.files ?? {});
      // Add files to the scene
      excalidrawAPI.addFiles(filesArray);

      console.log("initial elements",initialData.elements);
      // Update the elements
      excalidrawAPI.updateScene({
        elements: initialData.elements ?? [],
      });

      const allElements = excalidrawAPI.getSceneElements();
      console.log("All elements after update:", allElements);
    }
  }, [excalidrawAPI, initialData]);

  const saveToLocalStorage = (
    index: number,
    submissionKey: string,
    elements: readonly ExcalidrawElement[]
  ) => {
    console.log(
      `Saving to localStorage for ${submissionKey}_excalidrawData_${index}`,
      JSON.stringify(elements)
    );
    if (submissionKey) {
      localStorage.setItem(
        `${submissionKey}_excalidrawData_${index}`,
        JSON.stringify(elements)
      );
    }
  };

  const loadImage = (
    dataURLString: string,
    index: number,
    submissionKey: string
  ) => {
    const fileId = `${submissionKey}_localImage${index}` as FileId;
    const dataURL = dataURLString as DataURL;

    const img = new Image();
    img.onload = () => {
      let elements: readonly ExcalidrawElement[] = [];

      const savedData = localStorage.getItem(
        `${submissionKey}_excalidrawData_${index}`
      );
      if (savedData) {
        try {
          const parsedData = JSON.parse(savedData);
          if (Array.isArray(parsedData) && parsedData.length > 0) {
            elements = parsedData;
          }
        } catch (error) {
          console.error("Error parsing saved data:", error);
        }
      }

      // Generate a unique ID for the new image element
      const newImageElementId = uuidv4();

      const imageElement: ExcalidrawImageElement = {
        type: "image",
        id: newImageElementId,
        fileId,
        status: "saved",
        x: 0,
        y: 0,
        width: img.width / 2,
        height: img.height / 2,
        isDeleted: false,
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        version: 1,
        seed: Math.floor(Math.random() * 2 ** 31),
        versionNonce: 0,
        strokeColor: "#000000",
        backgroundColor: "transparent",
        roundness: null,
        groupIds: [],
        frameId: null,
        link: null,
        scale: [1, 1],
        locked: true,
        boundElements: null,
        updated: Date.now(),
        customData: {},
        isBinding: false,
        name: null,
      } as unknown as ExcalidrawImageElement;

      // Ensure the image element is the first element
      elements = [
        imageElement,
        ...elements.filter((el) => el.type !== "image"),
      ];

      imageElementsRef.current[index] = elements;

      const newInitialData: ImportedDataState = {
        files: {
          [fileId]: {
            id: fileId,
            dataURL,
            mimeType: "image/jpeg",
            created: Date.now(),
          },
        },
        elements: elements,
      };

      setInitialData(newInitialData);
      setCurrentImageIndex(index);

      saveToLocalStorage(index, submissionKey, elements);
    };
    img.src = dataURLString;
  };

  const handleImageLoad = (index: number) => {
    if (images[index]) {
      const imageUrl = images[index];
      fetch(imageUrl)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            })
        )
        .then((dataUrl) => {
          loadImage(dataUrl, index, submissionKey);
        })
        .catch((error) => {
          console.error("Error loading image from S3:", error);
        });
    }
  };

  const onChange = (
    elements: readonly ExcalidrawElement[],
    appState: any
  ) => {
    console.log(
      "onChange called with submissionKey:",
      submissionKeyRef.current,
      "currentImageIndex:",
      currentImageIndexRef.current
    );
    imageElementsRef.current[currentImageIndexRef.current] = elements;
    saveToLocalStorage(
      currentImageIndexRef.current,
      submissionKeyRef.current,
      elements
    );
  };

  // Include the handleOverlayModeSubmit function
  const handleOverlayModeSubmit = async (formData: OverlayModeFormData) => {
    console.log("Overlay mode form data:", formData);

    const formDataToSend = new FormData();

    const file_1_url = localStorage.getItem(formData.currentSubmission);
    const file_2_url = localStorage.getItem(formData.comparisonSubmission);

    try {
      // Fetch the images from S3
      const [file1Response, file2Response] = await Promise.all([
        fetch(file_1_url as RequestInfo),
        fetch(file_2_url as RequestInfo),
      ]);

      // Convert the responses to Blob objects
      const file1Blob = await file1Response.blob();
      const file2Blob = await file2Response.blob();

      // Append the Blob objects to the FormData
      formDataToSend.append(
        "file1",
        file1Blob,
        `${formData.currentSubmission}.png`
      );
      formDataToSend.append(
        "file2",
        file2Blob,
        `${formData.comparisonSubmission}.png`
      );
      formDataToSend.append("color1", "0,0,255");
      formDataToSend.append("color2", "255,0,0");

      // Send the FormData using axios
      const response = await axios.post(
        `${imgComparisionUrl}/api/upload_png/`,
        formDataToSend
      );
      console.log("Success:", response.data);

      // Extract the updated image from the response
      const updatedImage = response.data.results[0].images.find(
        (img: any) => img.type === "Comb"
      ).image;

      // Generate a unique ID for the new image element and file
      const newFileId = uuidv4() as FileId;
      const newDataURL = `data:image/png;base64,${updatedImage}` as DataURL;

      const newFileData: BinaryFileData = {
        id: newFileId,
        dataURL: newDataURL,
        mimeType: "image/png",
        created: Date.now(),
      };

      const newImageElementId = uuidv4(); // Element IDs are strings
      const newImageElement: ExcalidrawImageElement = {
        type: "image",
        id: newImageElementId,
        fileId: newFileId,
        status: "saved",
        x: 0,
        y: 0,
        width: 1920,
        height: 1080,
        isDeleted: false,
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        version: 1,
        seed: Math.floor(Math.random() * 2 ** 31),
        versionNonce: 0,
        strokeColor: "#000000",
        backgroundColor: "transparent",
        roundness: null,
        groupIds: [],
        frameId: null,
        link: null,
        scale: [1, 1],
        locked: true,
        boundElements: null,
        updated: Date.now(),
        customData: {},
        isBinding: false,
        name: null,
      } as unknown as ExcalidrawImageElement;

      if (excalidrawAPI) {
        const currentElements = excalidrawAPI.getSceneElements();
        const updatedElements = [...currentElements, newImageElement];

        // Add the new file (wrap in an array)
        excalidrawAPI.addFiles([newFileData]);

        // Update the elements
        excalidrawAPI.updateScene({
          elements: updatedElements,
        });

        // Save updated elements to localStorage
        saveToLocalStorage(currentImageIndex, submissionKey, updatedElements);
      }
    } catch (error: any) {
      console.error("Error in handleOverlayModeSubmit:", error);
    }
  };

  return (
    <>
      {!initialData ? (
        <div className="flex items-center justify-center mt-5">Loading...</div>
      ) : (
        <ExcalidrawComponent
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={initialData}
          onChange={onChange}
          overlaymodeSelect={overlaymodeSelect}
          onOverlayModeSubmit={handleOverlayModeSubmit}
        >
          <Footer>
            <div
              className="Stack Stack_horizontal ml-auto mr-[0.6em]"
              style={{ gap: 6 }}
            >
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleImageLoad(index)}
                  className="whitespace-nowrap custom-footer bg-white shadow-md rounded-md px-3 py-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  Page {index + 1}
                </button>
              ))}
            </div>
          </Footer>
        </ExcalidrawComponent>
      )}
    </>
  );
}
